import React, { useEffect, useRef } from "react"
import { navigate, Link } from "gatsby"
import { getApiEndpoint, isEDMode, getNested } from '@src/helper';

// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"


const UnsubscribeConfirmed = ({location}) => {

    if (isEDMode) {
        location.state = {
            values: {
                email: '[name@domain.com]',
                unsubscribeAuriniaAlliance: true,
                unsubscribeDiseaseAwareness: true,
                unsubscribeCorporate: true
            }
        }
    }
    const locationState = useRef(location?.state?.values)
    useEffect(()=>{
        //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect
        if(!isEDMode && !getNested(location,'state', 'values', 'email')) {

            navigate('/unsubscribe');
        }
    },[])

    const resubscribe = (e) => {

        if(e.type === 'click' || e.code === 'Enter'){
            e.preventDefault()
            const unSubToken = location.state.token ? {token: location.state.token} : {}
            fetch(getApiEndpoint() + 'consumer/resubscribe', {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(unSubToken),
            })
            .then(async (resp) => {
                var r = await resp.json();
                if (r.success) {
                    navigate('/resubscribe-confirmed',
                        {
                            state: {
                                values: location.state.values
                            }
                        }
                    );
                } else {
                    navigate('/500');
                }
            })
            .catch((err) => {
                navigate('/500');
            })
        }

    }

    return (
        <Layout addedClass="page--unsubscribe-confirmed">
            <Seo title="Success - Unsubscribed  | LUPKYNIS&reg; (voclosporin)" description="" robots="noindex" canonicalURL="https://www.lupkynis.com/unsubscribe-confirmed" />
            <Hero addedClass="bg-light-green hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">It's official</h1>
                            <h2>You've unsubscribed</h2>

                            <p>{locationState?.current?.email}, you've chosen to stop receiving marketing communications from LUPKYNIS.</p>
                            {(locationState?.current?.unsubscribeAuriniaAlliance || locationState?.current?.unsubscribeDiseaseAwareness || locationState?.current?.unsubscribeCorporate) && <p>You have also chosen to unsubscribe from the following marketing communications:</p>}
                            <ul>
                                {locationState?.current?.unsubscribeAuriniaAlliance &&
                                    <li>Marketing communications about patient support from Aurinia Alliance<sup>&reg;</sup></li>
                                }
                                {locationState?.current?.unsubscribeDiseaseAwareness &&
                                    <li>Disease state awareness information</li>
                                }
                                {locationState?.current?.unsubscribeCorporate &&
                                    <li>Corporate communications from Aurinia, the makers of LUPKYNIS</li>
                                }
                            </ul>
                            <p>If you've changed your mind, you can resubscribe <a tabIndex="0" onClick={resubscribe} onKeyDown={resubscribe}>here</a>. You can always come back later to resubscribe.</p>
                            <Link to="/" className="button">Return to home</Link>
                        </div>
                        <div className="hero__image">
                            <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                        </div>
                    </div>
                </div>
            </Hero>
        </Layout>
    )
}

export default UnsubscribeConfirmed
